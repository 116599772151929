import { cloneDeep } from 'lodash';

import { SET_PUNTEGGI_SQUADRE_PROBLEMI, ADD_PUNTEGGI_EVENTO, SET_MARKOV_STATO, RESET_EVENTI, CHANGE_PARAMETERS } from '../actions/action-types'
import {
  SquadraInSessione, EventoPunteggi as Evento, Evento as EventoGas, PunteggiStatoBackend, RisoltoStato as Risolto, JollyStato as Jolly,
  OspitiStato as Ospiti, PunteggiStato, PunteggiStateInternal, ClassificaEntry, TunableParametersForm, TunableParameters
} from '../api/types'
import { JsonClassifica } from '../api/websocket'
import { PunteggiDistributedAction } from '../actions'
import { range, sortedIndex, sentry_log } from '../utils'


const linear = (maxvalue: number, pos: number, maxpos: number) => Math.floor(maxvalue * Math.exp(-4 * pos / maxpos))


export interface PunteggiState {
  [sessione: string]: PunteggiStateInternal
}


const original_stato: (evento: EventoGas, s: SquadraInSessione[], probnum: number) => PunteggiStato = (evento, squadre, probnum) => {
  // A = ERRORE_PUNTEGGI_RAISE
  // h = MAX_ERRORI_RAISE_MONTEPREMI
  let origin = new Date();
  origin.setUTCSeconds(0);
  return {
    timestamp: origin,
    np: probnum,
    MAX_ERRORI_RAISE_MONTEPREMI: evento.MAX_ERRORI_RAISE_MONTEPREMI,
    ERRORE_PUNTEGGI_RAISE: evento.ERRORE_PUNTEGGI_RAISE,
    data: new Date(evento.inizio),
    PTI_TOLTI_ERRORE: evento.PTI_TOLTI_ERRORE,
    PTI_BASE_MONTEPREMI: evento.PTI_BASE_MONTEPREMI,
    PUNTEGGIO_BASE_PROBLEMI: range(probnum).map(_ => evento.PUNTEGGIO_BASE_PROBLEMI),
    BONUS_VELOCITA: evento.BONUS_VELOCITA,
    SCALING_BONUS_VELOCITA: parseFloat(evento.SCALING_BONUS_VELOCITA),
    SCALING_BONUS_MONTEPREMI: parseFloat(evento.SCALING_BONUS_MONTEPREMI),
    myolifis: squadre.reduce(
      (dict, sq) => {
        dict[sq.id.toString()] = sq.myolifis_id;
        return dict;
      }, {} as { [pk: string]: number }
    ),
    nomi: squadre.reduce(
      (dict, sq) => {
        dict[sq.id.toString()] = sq.nome
        return dict
      }, {} as { [pk: string]: string }
    ),
    ospiti: squadre.filter(sq => sq.ospite).map(sq => sq.id.toString()),
    contenuto: {
      risolto: squadre.reduce(
        (dict, sq) => {
          dict[sq.id.toString()] = range(probnum).map(_ => [false, 0, -1])
          return dict
        }, {} as Risolto),
      ordine_risoluzione: range(probnum).map(_ => [] as number[]),
      jolly: {},
      risolto_tutti: [],
    }
  }
}




const update_contenuto = (stato: PunteggiStato, evento: Evento) => {
  const ordine = evento.problema;
  const pk = evento.squadra;
  const pks = pk.toString();
  stato.timestamp = evento.date;
  if (!Object.keys(stato.contenuto.risolto).includes(pks)) {
    // Squadra non esistente, non dovrebbe mai succedere.
    const err = new Error("Evento con squadra non esistente (in avanti): " + JSON.stringify(evento))
    sentry_log(err)
    return;
  }

  if (evento.kind == "j") {
    stato.contenuto.jolly[pks] = ordine;
    return;
  }
  if (!evento.corretto) {
    (stato.contenuto.risolto[pks][ordine][1] as number) += 1;
    return;
  }

  if (!stato.contenuto.risolto[pks][ordine][0]) {
    stato.contenuto.risolto[pks][ordine][0] = true;
    stato.contenuto.risolto[pks][ordine][2] = evento.id;
    stato.contenuto.ordine_risoluzione[ordine].push(pk);
  }
  const fatti_tutti = stato.contenuto.risolto[pks].reduce((val, added) => val && added[0], true)
  if (fatti_tutti && !stato.contenuto.risolto_tutti.includes(pk)) {
    stato.contenuto.risolto_tutti.push(pk);
  }
}


/*
 * Attenzione, non è davvero l'inverso di update_contenuto. Rimuove solo le risposte e
 * non i jolly, che tanto commutano.
 */
const update_contenuto_backwards = (stato: PunteggiStato, evento: Evento) => {
  if (evento.kind == "j") return

  const ordine = evento.problema;
  const pk = evento.squadra;
  const pks = pk.toString();
  if (!Object.keys(stato.contenuto.risolto).includes(pks)) {
    // Squadra non esistente, non dovrebbe mai succedere.
    const err = new Error("Evento con squadra non esistente (in avanti): " + JSON.stringify(evento))
    sentry_log(err)
    return;
  }


  // Tolgo un errore
  if (!evento.corretto) {
    stato.contenuto.risolto[pks][ordine][1] -= 1;
    return
  }

  // Qualcuno ha risposto giusto due volte alla stessa domanda
  if (stato.contenuto.risolto[pks][ordine][2] != evento.id) return

  stato.contenuto.risolto[pks][ordine][0] = false;
  stato.contenuto.risolto[pks][ordine][2] = -1;

  if (stato.contenuto.ordine_risoluzione[ordine].slice(-1)[0] != pk) {
    console.error("Rotto out of order", pk)
    return;
  }
  stato.contenuto.ordine_risoluzione[ordine].pop();
  if (stato.contenuto.risolto_tutti.slice(-1)[0] == pk) {
    stato.contenuto.risolto_tutti.pop();
  }
}


export function punteggiMarkovReducer(state: PunteggiState = {}, action: PunteggiDistributedAction) {

  if (action.type == SET_PUNTEGGI_SQUADRE_PROBLEMI) {
    const spk = action.sessione.toString();
    const eventi = state.hasOwnProperty(spk) ? state[spk].eventi : [];
    const stato = original_stato(action.evento_gas, action.squadre, action.num_problemi);
    return {
      ...state,
      [spk]: {
        stato: cloneDeep(stato),  // Sono due oggetti diversi e devono essere scorrelati
        stato_original: stato,
        eventi: eventi,
        squadre: action.squadre,
      }
    }
  } else if (action.type == CHANGE_PARAMETERS) {
    const spk = action.sessione.toString();
    const old = state[spk];
    const pars = cloneDeep(action.newparameters) as TunableParametersForm;
    (pars as unknown as TunableParameters).PUNTEGGIO_BASE_PROBLEMI = range(old.stato.np).map(_ => pars.PUNTEGGIO_BASE_PROBLEMI)
    const pars2 = cloneDeep(pars) as unknown as TunableParameters;
    const newstato = {
      ...old.stato,
      ...pars2,
    }
    const newstato_original = {
      ...old.stato_original,
      ...pars2,
    }
    return {
      ...state,
      [spk]: {
        ...old,
        stato: newstato,
        stato_original: newstato_original,
      }
    }

  } else if (action.type == SET_MARKOV_STATO) {
    // Setta lo stato a quello nuovo
    // Filtra via gli eventi del passato
    // Ricalcola lo stato corrente in funzione degli eventi dopo

    let newstate = cloneDeep(state)
    const { risolto } = action.stato.contenuto;
    const keys = Object.keys(risolto)
    const np = (keys.length != 0) ? risolto[keys[0]].length : 0;
    const evento = action.evento_gas;
    const spk = action.sessione.toString();
    const neworiginal = {
      np: np,
      MAX_ERRORI_RAISE_MONTEPREMI: evento.MAX_ERRORI_RAISE_MONTEPREMI,
      ERRORE_PUNTEGGI_RAISE: evento.ERRORE_PUNTEGGI_RAISE,
      data: new Date(evento.inizio),
      PTI_TOLTI_ERRORE: evento.PTI_TOLTI_ERRORE,
      PTI_BASE_MONTEPREMI: evento.PTI_BASE_MONTEPREMI,
      PUNTEGGIO_BASE_PROBLEMI: range(np).map(_ => evento.PUNTEGGIO_BASE_PROBLEMI),
      BONUS_VELOCITA: evento.BONUS_VELOCITA,
      SCALING_BONUS_VELOCITA: parseFloat(evento.SCALING_BONUS_VELOCITA),
      SCALING_BONUS_MONTEPREMI: parseFloat(evento.SCALING_BONUS_MONTEPREMI),
      ...action.stato,
      timestamp: new Date(Date.parse(action.stato.timestamp))
    }
    newstate[spk].stato = cloneDeep(neworiginal);
    newstate[spk].stato_original = neworiginal;
    // Filtra via eventi del passato
    newstate[spk].eventi = newstate[spk].eventi.filter(ev => ev.date.getTime() > neworiginal.timestamp.getTime())

    // Aggiorna stato con eventi del futuro
    for (const ev of newstate[spk].eventi) {
      update_contenuto(newstate[spk].stato, ev)
    }
    return newstate;
  } else if (action.type == ADD_PUNTEGGI_EVENTO) {
    const spk = action.sessione.toString()
    // Inseriamo l'evento in modo ordinato
    const idx = sortedIndex(
      state[spk].eventi, action.evento,
      (a, b) => a.date.getTime() < b.date.getTime()
    )
    const oldeventi = state[spk].eventi;
    const sub = oldeventi[idx]
    if (!!sub && sub.id == action.evento.id && sub.kind == action.evento.kind) {
      // Evento duplicato.
      return state
    }
    const nuovieventi = [
      ...oldeventi.slice(0, idx),
      action.evento,
      ...oldeventi.slice(idx),
    ]

    // Se non è l'ultimo è male
    if (idx != nuovieventi.length - 1) {
      const nuovostato = cloneDeep(state[spk].stato)
      for (let i = nuovieventi.length - 1; i > idx; i--) {
        const ev = nuovieventi[i];
        update_contenuto_backwards(nuovostato, ev)
      }

      for (let i = idx; i < nuovieventi.length; i++) {
        const ev = nuovieventi[i]
        update_contenuto(nuovostato, ev)
      }
      return {
        ...state,
        [spk]: {
          ...state[spk],
          eventi: nuovieventi,
          stato: nuovostato,
        }
      };
    }
    const nuovostato = cloneDeep(state[spk].stato)
    update_contenuto(nuovostato, action.evento)
    return {
      ...state,
      [spk]: {
        ...state[spk],
        eventi: nuovieventi,
        stato: nuovostato,
      }
    };
  } else if (action.type == RESET_EVENTI) {
    // Questo serve solo perché la libreria di testing fa schifo
    const sqpk = action.sessione.toString()
    return {
      ...state,
      [sqpk]: {
        ...state[sqpk],
        eventi: [],
      }
    }

  } else {
    return state;
  }
}


const calcola_attive = (stato: PunteggiStato) => {
  let count = 0;
  for (const [sqpk, arr] of Object.entries(stato.contenuto.risolto)) {
    if (stato.ospiti.includes(sqpk)) continue;
    if (arr.reduce((acc, val) => acc || val[0], false)) {
      count += 1;
    }
  }
  return count;
}



const calcola_risolutori_errori = (state: PunteggiStato, attive_cap: number) => {
  const { np, MAX_ERRORI_RAISE_MONTEPREMI } = state;
  const h = MAX_ERRORI_RAISE_MONTEPREMI;
  const risolutori = range(np).map(_ => 0)
  const lista_errori = range(np).map(_ => 0)
  for (const [sqpk, arr] of Object.entries(state.contenuto.risolto)) {
    if (state.ospiti.includes(sqpk)) continue
    arr.map((entr, idx) => {
      if (entr[0]) risolutori[idx] += 1;
      lista_errori[idx] += Math.min(entr[1] as number, h) / attive_cap;

    })
  }
  return [risolutori, lista_errori];
}

type Target = { [pk: string]: number } | { [pk: string]: number[] }

const traverse_bonus = (trgt: Target, iterable: number[], guests: Ospiti, total: number, maxpos: number, extraidx: number = null) => {
  let uff = 0;

  for (let index = 0; index < iterable.length; index++) {
    const sq = iterable[index].toString();
    const val = linear(total, guests.includes(sq) ? index : uff, maxpos);
    if (extraidx === null) {
      (trgt[sq] as number) += val;
    } else {
      (trgt[sq] as number[])[extraidx] += val;
    }

    if (guests.includes(sq)) continue;
    if (val < 1) break;
    uff += 1
  }
}


/**
 * Questo è il bonus velocità
 */
const calcola_bonus_problema = (state: PunteggiStato, attive_cap: number) => {
  const { np } = state;
  const bonus = {} as { [sqpk: string]: number[] }
  Object.keys(state.contenuto.risolto).forEach(sqpk => {
    bonus[sqpk] = range(np).map(_ => 0)
  })

  for (let prob = 0; prob < state.contenuto.ordine_risoluzione.length; prob++) {
    const sq_list = state.contenuto.ordine_risoluzione[prob];
    traverse_bonus(bonus, sq_list, state.ospiti, state.BONUS_VELOCITA, state.SCALING_BONUS_VELOCITA * Math.sqrt(attive_cap), prob)
  }
  return bonus;
}


const calcola_pti_montepremi = (state: PunteggiStato, f: number[], K: number[], attive_cap: number) => {
  const { np, ERRORE_PUNTEGGI_RAISE, PTI_BASE_MONTEPREMI } = state;
  const A = ERRORE_PUNTEGGI_RAISE;
  return range(np).map(i => linear(
    PTI_BASE_MONTEPREMI + A * f[i],
    K[i] - 1,
    state.SCALING_BONUS_MONTEPREMI * attive_cap,
  ));
}


const calcola_bonus_tutti_2023 = (state: PunteggiStato, attive_cap: number) => {
  const { np, PTI_TOLTI_ERRORE } = state;
  const ret = {} as { [pk: string]: number }

  Object.keys(state.contenuto.risolto).forEach(key => {
    ret[key] = PTI_TOLTI_ERRORE * np;
  })
  traverse_bonus(
    ret, state.contenuto.risolto_tutti,
    state.ospiti, 20 * np,
    Math.sqrt(2 * attive_cap),
  )
  return ret;
}

const calcola_bonus_tutti_2024 = (state: PunteggiStato, attive_cap: number) => {
  const { np, PTI_TOLTI_ERRORE } = state;

  const ret = {} as { [pk: string]: number }

  Object.entries(state.contenuto.risolto).forEach(([key, arr]) => {
    // - Ha scelto il jolly
    // - ha consegnato una risposta, giusta o sbagliata
    const active = chosen_jolly(state, key) || arr.reduce((acc, val) => acc || val[0] || val[1] != 0, false);
    const val = active ? PTI_TOLTI_ERRORE * np : 0;
    ret[key] = val;
  })
  traverse_bonus(
    ret, state.contenuto.risolto_tutti,
    state.ospiti, 20 * np,
    Math.sqrt(2 * attive_cap),
  )
  return ret;

}


type PunteggioPerProblema = { [sqpk: string]: [number, number, number][] }

const calcola_punteggio_per_problema = (state: PunteggiStato, bonus_problema: { [sqpk: string]: number[] }, pti_montepremi: number[]) => {
  const { np, PUNTEGGIO_BASE_PROBLEMI, PTI_TOLTI_ERRORE } = state;
  const punteggi = {} as PunteggioPerProblema
  Object.keys(state.contenuto.risolto).forEach(key => {
    punteggi[key] = range(np).map(_ => [0, 0, 0])
  })

  Object.entries(bonus_problema).forEach(([sq, bonlist]) => {
    state.contenuto.risolto[sq].map((val, i) => {
      if (val[0]) {
        punteggi[sq][i][0] += bonlist[i] + PUNTEGGIO_BASE_PROBLEMI[i];
        punteggi[sq][i][1] += pti_montepremi[i];
      }
      punteggi[sq][i][2] -= val[1] * PTI_TOLTI_ERRORE;
    })
    const j = number_cast(state.contenuto.jolly[sq]);
    if (j !== null) {
      punteggi[sq][j] = punteggi[sq][j].map(val => 2 * val) as [number, number, number]
    }
  })

  return punteggi
}


const get_jolly_pts = (squadra: ClassificaEntry) => {
  const { jolly, problemi } = squadra;
  if (jolly === null) return 0
  return problemi[jolly].reduce((acc, val) => acc + val, 0)
}

const number_cast = (val?: number) => {
  if (val === undefined || val == null) return null;
  if (typeof val == "number") return val
  return null;
}

const chosen_jolly = (state: PunteggiStato, key: string) => {
  const jolly = state.contenuto.jolly[key];
  return typeof jolly == "number";
}

const get_functions = (date: Date) => {
  const _2024 = new Date("2023-07-01T00:00:00+0000")
  if (date.getTime() < _2024.getTime()) {
    return calcola_bonus_tutti_2023;
  }
  return calcola_bonus_tutti_2024;
}

export const stateToScore: (s: PunteggiStateInternal, r: Evento[]) => JsonClassifica["json"] = (state, recenti) => {

  const { stato } = state
  const sq_total = Object.keys(stato.contenuto.risolto).length;

  const calcola_bonus_tutti = get_functions(stato.data);

  const attive = calcola_attive(stato);
  const attive_cap = Math.max(attive, sq_total / 2, 5);
  const [K_i, f_i] = calcola_risolutori_errori(stato, attive_cap)
  const bonus_problema = calcola_bonus_problema(stato, attive_cap)
  const pti_montepremi = calcola_pti_montepremi(stato, f_i, K_i, attive_cap);
  // Quando rispondi, in realtà prendi meno punti
  const pti_montepremi_dopo = calcola_pti_montepremi(stato, f_i, K_i.map(k => k + 1), attive_cap);
  const punteggio_per_problema = calcola_punteggio_per_problema(stato, bonus_problema, pti_montepremi)
  const bonus_tutti = calcola_bonus_tutti(stato, attive_cap);
  const sq_order = Object.fromEntries(state.squadre.map(sq => [sq.id.toString(), sq.ordine_eta]));


  let classifica = [] as ClassificaEntry[]
  Object.keys(bonus_tutti).forEach(sq => {
    classifica.push({
      id: parseInt(sq),
      nome: stato.nomi[sq],
      myolifis_id: stato.myolifis[sq],
      problemi: punteggio_per_problema[sq],
      ordine_eta: sq_order[sq],
      jolly: number_cast(stato.contenuto.jolly[sq]),
      nuovi: [...new Set(recenti.filter(ev => ev.squadra.toString() == sq).map(ev => ev.problema))],
      punti_extra: bonus_tutti[sq],
      punteggio_totale: bonus_tutti[sq] + punteggio_per_problema[sq].reduce(
        (acc, val) => acc + val.reduce((acc2, val2) => acc2 + val2, 0),
        0,
      ),
      ospite: stato.ospiti.includes(sq),
    })
  })
  const comp = (a: ClassificaEntry, b: ClassificaEntry) => {
    if (b.punteggio_totale != a.punteggio_totale) {
      return b.punteggio_totale - a.punteggio_totale
    }
    const ja = get_jolly_pts(a)
    const jb = get_jolly_pts(b)

    if (ja != jb) {
      return jb - ja
    }
    return a.ordine_eta - b.ordine_eta
  }

  classifica.sort(comp)

  return {
    giuste: stato.contenuto.ordine_risoluzione.map(val => val.length),
    classifica: classifica,
    punteggi_problemi: pti_montepremi_dopo.map(val => val + 20)
  }
}
